import { createStore } from 'vuex'

export default createStore({
  state: {
    SideMenuData: {},
    MenuData: [],
    ViewMenuData: {},
    loginInfo: {},
    PageValue: [],
    NextData: []
  },
  mutations: {
    setPageValue: function (state, PageValue) {
      state.PageValue = PageValue
    },
    setSideMenuData: function (state, sideMenuData) {
      state.SideMenuData = sideMenuData
    },
    setMenuData: function (state, MenuData) {
      state.MenuData = MenuData
    },
    setViewMenuData: function (state, ViewMenuData) {
      state.ViewMenuData = ViewMenuData
    },
    setLoginInfo: function (state, loginInfo) {
      state.loginInfo = loginInfo
    },
    setNexData: function (state, nextData) {
      state.NextData = nextData
    }
  },
  getters: {
    getSideMenuData (state) {
      return state.SideMenuData
    },
    getMenuData (state) {
      return state.MenuData
    },
    getViewMenuData (state) {
      return state.ViewMenuData
    },
    getLoginInfo (state) {
      return state.loginInfo
    },
    getNexData (state) {
      return state.nextData
    }
  },
  actions: {
  },
  modules: {
  }
})
