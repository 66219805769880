import { createApp } from 'vue'
import index from './index.vue'
import router from './router'
import store from './store'
import './assets/css/base.css'
import './assets/css/icon/iconfont.css'
import lang from 'element-plus/lib/locale/lang/zh-cn'
import locale from 'element-plus/lib/locale'

// import 'element-plus/lib/theme-chalk/index.css'
import {
  ElNotification, ElTree, ElRadio, ElRadioGroup, ElRadioButton,
  ElMenu, ElMenuItem, ElTable, ElCheckbox, ElCheckboxGroup, ElCheckboxButton,
  ElTableColumn, ElPagination, ElInput, ElInputNumber, ElFormItem, ElForm, ElSelect, ElOption, ElTabPane, ElTabs, ElButtonGroup,
  ElSteps, ElStep, ElButton, ElBreadcrumb, ElBreadcrumbItem, ElMessageBox, ElMessage, ElIcon, ElCol, ElRow, ElCollapseItem,
  ElUpload, ElTooltip, ElDatePicker, ElDialog, ElCollapse, ElTransfer, ElAutocomplete, ElMenuItemGroup, ElSubmenu, ElCard, ElDrawer, ElDropdown, ElDropdownMenu, ElDropdownItem, ElCascader
} from 'element-plus'

locale.use(lang)

const app = createApp(index).use(store).use(router)
app.use(ElNotification).use(ElTree).use(ElMenu).use(ElMenuItem).use(ElTabPane).use(ElTabs).use(ElButtonGroup)
  .use(ElTable).use(ElTableColumn).use(ElPagination).use(ElSteps).use(ElStep).use(ElButton).use(ElMenuItemGroup)
app.use(ElBreadcrumb).use(ElBreadcrumbItem).use(ElMessageBox).use(ElMessage).use(ElInput).use(ElInputNumber).use(ElFormItem).use(ElRow)
app.use(ElForm).use(ElIcon).use(ElSelect).use(ElOption).use(ElRadio).use(ElRadioGroup).use(ElRadioButton).use(ElDropdown).use(ElDropdownMenu).use(ElDropdownItem)
  .use(ElCheckbox).use(ElCheckboxGroup).use(ElCheckboxButton).use(ElCol).use(ElUpload).use(ElTooltip).use(ElDatePicker).use(ElDialog).use(ElCollapse).use(ElCollapseItem)
  .use(ElTransfer).use(ElAutocomplete).use(ElSubmenu).use(ElCard).use(ElDrawer).use(ElCascader)
app.mount('#app')
