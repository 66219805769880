import { createRouter, createWebHistory } from 'vue-router'
const routes = [
  {
    path: '/',
    redirect: '/index'
  },
  {
    path: '/Login',
    name: 'Login',
    component: () => import('@/views/login/index')
  }, {
    path: '/sso',
    name: 'sso',
    component: () => import('@/components/Sso')
  },
  {
    path: '/RedirectAction',
    name: 'RedirectAction',
    component: () => import('@/components/RedirectAction')
  },
  {
    path: '/PasswordReset',
    name: 'PasswordReset',
    component: () => import('@/views/login/loginComponents/login/PasswordReset')
  },
  {
    path: '/Register',
    name: 'Register',
    component: () => import('@/views/login/loginComponents/login/Register')
  },
  {
    path: '/index/',
    name: 'index',
    component: () => import('@/components/Index'),
    children: [
      {
        path: '',
        name: 'ShowBox',
        components: {
          default: () => import('@/views/index/ShowBox'),
          header: () => import('@/components/IndexHeader')
        }
      }
    ]
  },
  {
    path: '/:frameName/:viewName',
    name: 'View',
    component: () => import('@/components/Index'),
    children: [
      {
        path: '',
        name: 'frame',
        components: {
          default: () => import('@/components/ShowMain'),
          header: () => import('@/components/IndexHeader')
        }
      }
    ]
  },
  {
    path: '/InventoryDelivery/SupplierDemandNew',
    name: 'SupplierDemandNew',
    component: () => import('@/views/inventoryDelivery/supplierDeliver/supplierDemand/SupplierDemandNew.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
